var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sales-rank"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "销量查询",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-dropdown-menu', {
    ref: "menu",
    staticClass: "filter-container",
    attrs: {
      "active-color": "#1989fa",
      "close-on-click-outside": false
    }
  }, [_c('van-dropdown-item', {
    ref: "filter",
    attrs: {
      "title": "筛选条件"
    }
  }, [_c('div', [_c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.store,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "开始日期",
      "value": _vm.searchQuery.begin_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.chooseDate('begin_date');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "结束日期",
      "value": _vm.searchQuery.end_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.chooseDate('end_date');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "value": _vm.clientName,
      "title": "客户名称",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toClientView
    }
  }), _c('van-cell', {
    attrs: {
      "value": _vm.supplierName,
      "title": "供应商名称",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toSupplierView
    }
  }), _c('van-cell', {
    attrs: {
      "value": _vm.brandName,
      "title": "品牌名称",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toBrandView
    }
  }), _c('van-field', {
    attrs: {
      "label": "商品名称",
      "placeholder": "请输入商品名称",
      "clearable": ""
    },
    model: {
      value: _vm.searchQuery.goods_name,
      callback: function callback($$v) {
        _vm.$set(_vm.searchQuery, "goods_name", $$v);
      },
      expression: "searchQuery.goods_name"
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "total-style",
      "title": "统计方式",
      "center": ""
    }
  }, [_c('van-radio-group', {
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.searchQuery.type,
      callback: function callback($$v) {
        _vm.$set(_vm.searchQuery, "type", $$v);
      },
      expression: "searchQuery.type"
    }
  }, [_c('van-radio', {
    attrs: {
      "name": 1
    }
  }, [_vm._v("全部统计")]), _c('van-radio', {
    attrs: {
      "name": 2
    }
  }, [_vm._v("分商品统计")])], 1)], 1), _c('div', [_c('van-button', {
    staticStyle: {
      "display": "inline-block",
      "width": "50%"
    },
    attrs: {
      "square": "",
      "type": "warning"
    },
    on: {
      "click": _vm.handleReset
    }
  }, [_vm._v("重置")]), _c('van-button', {
    staticStyle: {
      "display": "inline-block",
      "width": "50%"
    },
    attrs: {
      "square": "",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("查询")])], 1)], 1)])], 1)], 1), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：销量查询不包含退货")]), _vm.listQuery.type === 2 ? _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.loadData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.goodsList, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "goods__image"
    }, [_c('van-image', {
      attrs: {
        "width": "2.26666rem",
        "height": "2.26666rem",
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "goods__info"
    }, [_c('span', {
      staticClass: "goods__name"
    }, [_vm._v(" " + _vm._s(goods.name) + " ")]), _c('span', {
      staticClass: "common-css goods__size"
    }, [_vm._v(" 规格：1*" + _vm._s(goods.size) + " ")]), _c('span', {
      staticClass: "common-css goods__total-sales"
    }, [_vm._v(" 销量："), goods.large_volume > 0 ? _c('span', {
      staticStyle: {
        "margin-right": "5px"
      }
    }, [_vm._v(_vm._s(goods.large_volume) + _vm._s(goods.large_unit))]) : _vm._e(), goods.least_volume > 0 ? _c('span', [_vm._v(_vm._s(goods.least_volume) + _vm._s(goods.least_unit))]) : _vm._e()]), _c('span', {
      staticClass: "common-css goods-amount"
    }, [_vm._v(" 金额：￥" + _vm._s(goods.amount) + " ")]), _c('span', {
      staticClass: "common-css goods__size"
    }, [_vm._v(" 剩余库存：" + _vm._s(goods.book_stock) + _vm._s(goods.least_unit) + " ")])])]);
  }), 0) : _c('div', {
    staticClass: "statistics"
  }, [_vm.total ? [_c('van-cell', {
    attrs: {
      "title": "金额",
      "value-class": "statistics-value",
      "value": '￥' + _vm.total.amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "数量",
      "value": _vm.total.quantity
    }
  })] : _vm._e()], 2)], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "confirm": _vm.handleDatePickerConfirm,
      "cancel": function cancel($event) {
        _vm.show = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }