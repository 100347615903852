<template>
  <div class="sales-rank">
    <my-nav-bar
      title="销量查询"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem">
        <van-dropdown-menu ref="menu" class="filter-container" active-color="#1989fa" :close-on-click-outside="false">
          <van-dropdown-item ref="filter" title="筛选条件">
            <div>
              <van-cell title="门店" :value="store" is-link center @click="showActions = true" />
              <van-cell title="开始日期" :value="searchQuery.begin_date" is-link center @click="chooseDate('begin_date')" />
              <van-cell title="结束日期" :value="searchQuery.end_date" is-link center @click="chooseDate('end_date')" />
              <van-cell :value="clientName" title="客户名称" is-link center @click="toClientView" />
              <van-cell :value="supplierName" title="供应商名称" is-link center @click="toSupplierView" />
              <van-cell :value="brandName" title="品牌名称" is-link center @click="toBrandView" />
              <van-field v-model="searchQuery.goods_name" label="商品名称" placeholder="请输入商品名称" clearable />
              <van-cell value-class="total-style" title="统计方式" center>
                <van-radio-group v-model="searchQuery.type" direction="horizontal">
                  <van-radio :name="1">全部统计</van-radio>
                  <van-radio :name="2">分商品统计</van-radio>
                </van-radio-group>
              </van-cell>
              <div>
                <van-button style="display:inline-block;width: 50%;" square type="warning" @click="handleReset">重置</van-button>
                <van-button style="display:inline-block;width: 50%;" square type="primary" @click="handleSearch">查询</van-button>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>
      <!-- <van-search v-model="search_key" placeholder="请输入商品名称" show-action class="my-search-css">
        <div slot="action" @click="handleSearch">搜索</div>
      </van-search> -->
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-else class="tips">提示：销量查询不包含退货</div>
      <van-list
        v-if="listQuery.type === 2"
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="loadData"
      >
        <div v-for="(goods,index) in goodsList" :key="index" class="goods">
          <div class="goods__image">
            <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
          </div>
          <div class="goods__info">
            <span class="goods__name">
              {{ goods.name }}
            </span>
            <span class="common-css goods__size">
              规格：1*{{ goods.size }}
            </span>
            <span class="common-css goods__total-sales">
              销量：<span v-if="goods.large_volume > 0" style="margin-right: 5px">{{ goods.large_volume }}{{ goods.large_unit }}</span>
              <span v-if="goods.least_volume > 0">{{ goods.least_volume }}{{ goods.least_unit }}</span>
            </span>
            <span class="common-css goods-amount">
              金额：￥{{ goods.amount }}
            </span>
            <span class="common-css goods__size">
              剩余库存：{{ goods.book_stock }}{{ goods.least_unit }}
            </span>
          </div>
        </div>
      </van-list>
      <div v-else class="statistics">
        <template v-if="total">
          <van-cell title="金额" value-class="statistics-value" :value="'￥'+total.amount" />
          <van-cell title="数量" :value="total.quantity" />
        </template>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" @click.native.stop>
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="handleDatePickerConfirm"
        @cancel="show = false"
      />
    </van-popup>

    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelect" />

    <!-- <div class="fixed-bottom-btn bottom-container">
      <span style="margin-right: 20px">总销量：{{ totalData.volume ? totalData.volume : 0 }}</span>
      <span>总金额：￥{{ totalData.amount ? totalData.amount : '0.00' }}</span>
    </div> -->

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import saleRankingAPI from '@/api/sales_ranking'
import { toDateString } from '@/utils'
import { getStores } from '@/api/home'
export default {
  name: 'SalesRank',
  components: { myNavBar },
  data() {
    return {
      loading: false,
      finished: true,
      listQuery: {
        page: 0,
        limit: 10
      },
      searchQuery: {
        store_id: undefined,
        begin_date: toDateString(new Date()),
        end_date: toDateString(new Date()),
        goods_name: undefined,
        client_id: undefined,
        supplier_id: undefined,
        brand_id: undefined,
        type: 2
      },
      clientName: '',
      supplierName: '',
      brandName: '',
      goodsList: [],
      currentDate: new Date(),
      show: false,
      chooseType: '',
      totalData: {
        volume: 0,
        amount: 0
      },
      store: '',
      showActions: false,
      actions: [],
      showEmpty: false,
      total: null
    }
  },
  watch: {
    sortVal(newVal) {
      // this.finished = false
      // this.loadData()
      this.refresh()
    }
  },
  created() {
    this.$store.commit('setKeepAlive', this.$options.name)
    this.$eventBus.$on('select-client', (res) => {
      this.clientName = res.name
      this.searchQuery.client_id = res.id
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.filter.toggle()
        }, 100)
      })
    })

    this.$eventBus.$on('select-supplier', (res) => {
      this.supplierName = res.name
      this.searchQuery.supplier_id = res.id
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.filter.toggle()
        }, 100)
      })
    })

    this.$eventBus.$on('select-brand', (res) => {
      this.brandName = res.name
      this.searchQuery.brand_id = res.id
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.filter.toggle()
        }, 100)
      })
    })

    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          value: store.id,
          name: store.name
        })
      })
    })

    this.showEmpty = true
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/home') {
      this.$eventBus.$off('select-client')
      this.$eventBus.$off('select-supplier')
      this.$eventBus.$off('select-brand')
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    loadData() {
      this.listQuery.page++
      this.getList()
    },
    getList() {
      saleRankingAPI.getRankingGoodLists(this.listQuery).then(res => {
        if (this.listQuery.type === 2) {
          this.goodsList = this.goodsList.concat(res.data)
          this.loading = false
          this.finished = res.data.length < this.listQuery.limit
          this.totalData = res.data.total_data
          this.showEmpty = this.goodsList.length === 0
        } else {
          this.total = res.data
        }
      }).catch(() => {
        this.finished = true
        this.showEmpty = true
      })
    },
    chooseDate(chooseType) {
      this.chooseType = chooseType
      this.show = true
    },
    refresh() {
      this.listQuery = {
        ...this.listQuery,
        ...this.searchQuery
      }
      console.log(this.listQuery)
      if (this.listQuery.type === 2) {
        this.listQuery.page = 0
        this.finished = false
        this.loading = false
        this.goodsList = []
        this.showEmpty = false
        this.$nextTick(() => {
          this.$refs.list.check()
        })
      } else {
        this.getList()
      }
    },
    handleSearch() {
      this.refresh()
      this.$refs.filter.toggle()
    },
    changeZIndex(type) {
      if (type === 'open') {
        this.$refs.menu.$el.style.zIndex = 3
      } else {
        this.$refs.menu.$el.style.zIndex = 2
      }
    },
    handleDatePickerConfirm(date) {
      if (this.chooseType === 'begin_date') {
        this.searchQuery.begin_date = toDateString(date)
      } else {
        this.searchQuery.end_date = toDateString(date)
      }
      this.show = false
    },
    handleSelect(action) {
      this.searchQuery.store_id = action.value
      this.store = action.name
      this.showActions = false
    },
    toClientView() {
      this.$refs.filter.toggle()
      this.$nextTick(() => {
        this.$router.push({
          path: '/sales-rank-client',
          query: {
            store_id: this.listQuery.store_id
          }
        })
      })
    },
    toSupplierView() {
      this.$refs.filter.toggle()
      this.$nextTick(() => {
        this.$router.push({
          path: '/sales-rank-supplier',
          query: {
            store_id: this.listQuery.store_id
          }
        })
      })
    },
    toBrandView() {
      this.$refs.filter.toggle()
      this.$nextTick(() => {
        this.$router.push({
          path: '/sales-rank-brand',
          query: {
            store_id: this.listQuery.store_id
          }
        })
      })
    },
    handleReset() {
      this.searchQuery.store_id = undefined
      this.searchQuery.begin_date = toDateString(new Date())
      this.searchQuery.end_date = toDateString(new Date())
      this.searchQuery.goods_name = undefined
      this.searchQuery.client_id = undefined
      this.searchQuery.supplier_id = undefined
      this.searchQuery.brand_id = undefined
      this.store = ''
      this.clientName = ''
      this.supplierName = ''
      this.brandName = ''
      this.refresh()
    }
  }
}
</script>

<style lang="scss">

.sales-rank {
    .filter-container {
      .van-dropdown-menu__bar {
        height: 40px!important;
      }
    }
  }

</style>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .my-search-css{
    position: fixed;
    left: 0;
    right: 0;
    top: 93px;
    z-index: 2;
    background-color: white;
  }
  .list {
    padding: 10px;
    .goods {
      margin-bottom: 10px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      &__info {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
      }
      .common-css{
        font-size: 12px;
        color: gray;
      }
      .goods-amount {
        color: $amountRedColor;
      }
    }
    .goods:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    background-color: $fixedFooterBtnColor;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
  .total-style {
    display: flex;
    flex: 4;
    justify-content: flex-end;
  }
  .statistics {
    padding: 10px;
  }
  .statistics-value {
    color: $amountRedColor;
  }
  .tips {
    padding: 10px 10px 0 10px;
    color: #989898;
  }
</style>
